import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import moment from 'moment';
import { Formik, Field, Form, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Puff } from 'react-loader-spinner';
import { flushSync } from 'react-dom';

import { Modal } from 'react-responsive-modal';
import { subYears } from 'date-fns';
import { NumericFormat } from 'react-number-format';
const StepOneForm = ({ updateStep, ownerInfo, setUsersData, stepOneDetails }) => {

    const apiUrl = process.env.REACT_APP_API_URL;
    const { clientkey, merchant_id } = useParams();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [userIndexToRemove, setUserIndexToRemove] = useState(null);

    // Functions to handle open/close state
    const handleOpen = (index, name) => { setUserIndexToRemove({ index: index, name: name }); setOpen(true) };
    const handleClose = () => { setUserIndexToRemove(null); setOpen(false) };
    // format phone number
    const formatPhoneNumber = (numericValue) => {
        const cleaned = ('' + numericValue).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `${'(' + match[1] + ') ' + match[2] + '-' + match[3]}`;
        }
        return numericValue;
    };
    // format SSN
    const formatSSN = (numericValue) => {
        const cleaned = ('' + numericValue).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{2})(\d{4})$/);
        if (match) {
            return `${match[1] + '-' + match[2] + '-' + match[3]}`;
        }
        return numericValue;
    };
    // Formik validation schema
    const validationSchema = Yup.object({
        users: Yup.array().of(
            Yup.object().shape({
                first_name: Yup.string().matches(/^[A-Za-z\s]+$/, "Name should only contain letters").required('First Name is required'),
                last_name: Yup.string().matches(/^[A-Za-z\s]+$/, "Name should only contain letters").required('Last Name is required'),
                email: Yup.string().email('Invalid email address').required('Email is required'),
                phone: Yup.string().matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Invalid phone number').required('Phone number is required'),
                date_of_birth: Yup.date().nullable().required('Date of birth is required'),
                address: Yup.string().required('Address is required'),
                city: Yup.string().required('City is required'),
                state: Yup.string().length(2, "Invalid State Code").required('State is required'),
                country: Yup.string().max(2, 'Only two characters required').required('Country is required'),
                zip: Yup.string().matches(/^\d{5}$/, 'Invalid zip code').required('Zip code is required'),
                b_title: Yup.string().required('Title is required'),
                ssn: Yup.string().matches(/^\d{3}-\d{2}-\d{4}$/, 'Invalid SSN').required('SSN is required'),
                license_number: Yup.string().max(15, 'Invalid License Number').matches(/^[a-zA-Z0-9]{1,15}$/, 'License Number must be alphanumeric'),
                ownership_percent: Yup.number().min(25, 'Ownership percent must be at least 25').max(100, 'Ownership percent cannot be more than 100').required('Ownership percent is required'),
                // fax_one: Yup.string(),
            })
        ),
    });


    const initialValues = {
        users: stepOneDetails?.ownership_detail?.length > 0 ? stepOneDetails?.ownership_detail?.map((item) => {
            return (
                {
                    phone: item.phone ? formatPhoneNumber(item.phone) : '',
                    first_name: item.first_name ? item.first_name : "",
                    last_name: item.last_name ? item.last_name : "",
                    date_of_birth: item.date_of_birth ? item.date_of_birth : "",
                    email: item.email ? item.email : "",
                    address: item.address ? item.address : "",
                    city: item.city ? item.city : "",
                    state: item.state ? item.state : "",
                    country: item.country ? item.country : "",
                    zip: item.zip ? item.zip : "",
                    b_title: item.b_title ? item.b_title : "",
                    ssn: item.ssn ? formatSSN(item.ssn) : "",
                    ownership_percent: item.ownership_percent ? item.ownership_percent : "",
                    license_number: item?.license_number ? item.license_number : ""

                }
            )
        })
            :
            [{
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                date_of_birth: "1990-01-01",
                address: '',
                city: '',
                state: '',
                zip: '',
                country: '',
                b_title: '',
                ssn: '',
                ownership_percent: 100,
                license_number: "",
            }
            ]
    };

    const handleSelect = async (address, setFieldValue, index) => {

        // setFieldValue(`users.${index}.address`, address?.split(",")[0]);

        const results = await geocodeByAddress(address);
        const { address_components } = results[0];

        address_components.forEach(component => {
            const { types, short_name } = component;
            if (types.includes('locality')) {
                setFieldValue(`users.${index}.city`, short_name);
            } else if (types.includes('administrative_area_level_1')) {
                setFieldValue(`users.${index}.state`, short_name);
            } else if (types.includes('country')) {
                setFieldValue(`users.${index}.country`, short_name);
            } else if (types.includes('postal_code')) {
                setFieldValue(`users.${index}.zip`, short_name);
            } else if (types.includes('route')) {
                setFieldValue(`users.${index}.address`, address);

            }
        });
        try {
            // const latLng = await getLatLng(results[0]);
        } catch (error) {
            console.error('Error fetching address coordinates:', error);
        }
    };


    // clean phone number
    const getCleanPhoneNumber = (formattedValue) => {
        return formattedValue.replace(/\D/g, '');
    };
    // clean SSN
    const getCleanSSN = (formattedValue) => {
        return formattedValue.replace(/\D/g, '');
    };
    // handle first step data
    const handleFirstStep = async (data) => {
        try {
            // let dataAfterclean = data?.map((item) => {
            //     return { ...item, phone: getCleanPhoneNumber(item.phone), ssn: getCleanSSN(item.ssn) }
            // })

            // return 
            setLoading(true);
            let finalData = {
                ownership_detail: [...data],
                merchant_id,
                step: 1,
            }

            let saveStepOneToLocalStorage = JSON.stringify({ ...stepOneDetails, ownership_detail: data })
            localStorage.setItem('client-details', saveStepOneToLocalStorage);


            if (merchant_id) {
                const response = await axios.post(
                    `${apiUrl}/merchant/onboarding/${clientkey}`, finalData, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    }
                });
                if (response.data.result) {
                    toast.success("Owner Information Updated Successfully")
                    updateStep();
                }
                else {
                    toast.error(response?.data?.message || "Something went wrong");
                }
            }
            else {
                flushSync(() => {
                    setUsersData((pre) => ({ ...pre, ownership_detail: [...data] }));
                });
                updateStep();
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (typeof (error?.response?.data?.message) === 'object') {
                error?.response?.data?.message?.map((item) => {
                    return toast.error(item)
                })
            } else {
                toast.error(error?.response?.data?.message || "Something went wrong");
            }
            console.log(error);
        }
    }

    // delete modal
    const confirmRemoveUser = (remove) => {

        remove(userIndexToRemove?.index);

        handleClose();
    };

    return (
        <>
            <div>
                <div className="heading-container">
                    <div className="heading-text">
                        <h2>Owner Information</h2>
                    </div>
                    <div className="heading-ico">
                        <img src="/owner-info.png" alt="Owner Info" />
                    </div>
                </div>
                <div className="add-all-owner">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_8936_24009)">
                            <path d="M7 0C5.61553 0 4.26216 0.410543 3.11101 1.17971C1.95987 1.94888 1.06266 3.04213 0.532846 4.32122C0.00303301 5.6003 -0.13559 7.00776 0.134506 8.36563C0.404603 9.7235 1.07129 10.9708 2.05026 11.9497C3.02922 12.9287 4.2765 13.5954 5.63437 13.8655C6.99224 14.1356 8.3997 13.997 9.67879 13.4672C10.9579 12.9373 12.0511 12.0401 12.8203 10.889C13.5895 9.73785 14 8.38447 14 7C13.9978 5.14416 13.2596 3.36495 11.9473 2.05267C10.6351 0.74039 8.85585 0.00218939 7 0ZM7 12.7273C5.86725 12.7273 4.75995 12.3914 3.8181 11.7621C2.87626 11.1327 2.14218 10.2383 1.70869 9.19173C1.27521 8.14521 1.16179 6.99365 1.38278 5.88266C1.60377 4.77168 2.14924 3.75118 2.95021 2.95021C3.75118 2.14923 4.77169 1.60376 5.88267 1.38277C6.99365 1.16179 8.14521 1.27521 9.19173 1.70869C10.2383 2.14217 11.1327 2.87625 11.7621 3.8181C12.3914 4.75994 12.7273 5.86725 12.7273 7C12.7254 8.5184 12.1214 9.97408 11.0477 11.0477C9.97408 12.1214 8.5184 12.7254 7 12.7273Z" fill="#35254D" />
                            <path d="M7.63494 6.73672V3.182C7.63494 3.01322 7.5679 2.85136 7.44856 2.73202C7.32921 2.61268 7.16735 2.54563 6.99858 2.54563C6.8298 2.54563 6.66794 2.61268 6.5486 2.73202C6.42926 2.85136 6.36222 3.01322 6.36222 3.182V7.00018C6.36225 7.16894 6.42932 7.33077 6.54867 7.45009L8.45776 9.35918C8.57778 9.47509 8.73853 9.53924 8.90538 9.53779C9.07223 9.53634 9.23184 9.46941 9.34983 9.35142C9.46781 9.23344 9.53474 9.07383 9.53619 8.90698C9.53764 8.74012 9.4735 8.57938 9.35758 8.45936L7.63494 6.73672Z" fill="#35254D" />
                        </g>
                        <defs>
                            <clipPath id="clip0_8936_24009">
                                <rect width="14" height="14" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    Add all owners with more than 25% ownership

                </div>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    enableReinitialize={stepOneDetails?.ownership_detail?.length > 0 ? true : false}
                    onSubmit={(values) => {

                        if (values?.users?.length === 0) {
                            return toast.error("Add atleast one owner");
                        }
                        let ownerPercent = 0;
                        values?.users?.map((item) => {
                            ownerPercent += item?.ownership_percent
                        })
                        if (ownerPercent > 100) {
                            toast.error("Ownership percent should not exceed 100%");
                            return
                        }
                        // check bot
                        // let check_bot = false;
                        // values?.users?.map((item) => {
                        //     if (item?.fax_one === "") check_bot = false;
                        //     else { check_bot = true; return false };

                        // });

                        // if (check_bot === true) return false;

                        let data = values?.users?.map((item, ind) => {
                            if (ind == 0) {
                                return { ...item, isPrimary: true, phone: getCleanPhoneNumber(item.phone), ssn: getCleanSSN(item.ssn) }
                            }
                            else {
                                return { ...item, isPrimary: false, phone: getCleanPhoneNumber(item.phone), ssn: getCleanSSN(item.ssn) }
                            }
                        })

                        handleFirstStep(data)
                    }}
                >
                    {({ setFieldValue, values, errors }) => (
                        <>
                            <Form>
                                <FieldArray name="users">
                                    {({ insert, remove, push }) => (
                                        <div className="tabs user-form ">
                                            {values.users.length > 0 &&
                                                values.users.map((user, index) => {
                                                    // if (index > 3) return
                                                    return (
                                                        <div className="tab  " key={index}>
                                                            <input type="checkbox" id={`chck${index}`} />
                                                            <label className={`tab-label`} htmlFor={`chck${index}`} >
                                                                {/* <label className={`tab-label ${errors.users && errors.users[index] ? 'has-error' : ''}`} htmlFor={`chck${index}`} > */}
                                                                <span>
                                                                    {`${values.users[index].first_name} ${values.users[index].last_name}`} <small>{values.users[index].b_title} • Ownership {values.users[index].ownership_percent}%</small>
                                                                </span>
                                                                <button type='button' className='delete' onClick={() => handleOpen(index, `${values.users[index].first_name} ${values.users[index].last_name}`)}>
                                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <g clipPath="url(#clip0_8936_24044)">
                                                                            <path d="M15.5 3.99998C15.5 3.54533 15.3173 3.10929 14.992 2.7878C14.6667 2.46631 14.2256 2.2857 13.7656 2.2857H11.453V1.71428C11.453 1.25962 11.2702 0.82359 10.9449 0.5021C10.6197 0.180611 10.1785 0 9.71851 0H6.24961C5.78961 0 5.34844 0.180611 5.02317 0.5021C4.6979 0.82359 4.51516 1.25962 4.51516 1.71428V2.2857H2.20257C1.79439 2.29312 1.40195 2.4426 1.09431 2.70784C0.786663 2.97308 0.583539 3.33707 0.520684 3.73575C0.45783 4.13444 0.539274 4.54226 0.750682 4.88743C0.96209 5.23261 1.28991 5.49302 1.67645 5.62283L2.67665 14.5199C2.73401 14.9309 2.94022 15.3074 3.25704 15.5795C3.57386 15.8517 3.97978 16.0011 4.39953 15.9999H11.5686C11.9903 16.0038 12.399 15.8557 12.7183 15.5833C13.0375 15.3109 13.2454 14.9329 13.303 14.5199L14.3032 5.62283C14.65 5.51107 14.9524 5.29409 15.1672 5.00274C15.3821 4.71139 15.4985 4.36051 15.5 3.99998ZM5.67146 1.71428C5.67146 1.56273 5.73237 1.41738 5.8408 1.31022C5.94922 1.20306 6.09628 1.14285 6.24961 1.14285H9.71851C9.87184 1.14285 10.0189 1.20306 10.1273 1.31022C10.2357 1.41738 10.2967 1.56273 10.2967 1.71428V2.2857H5.67146V1.71428ZM12.141 14.3942C12.1151 14.5263 12.043 14.6451 11.9372 14.7298C11.8315 14.8144 11.6989 14.8595 11.5628 14.8571H4.39953C4.26346 14.8595 4.13088 14.8144 4.02512 14.7298C3.91937 14.6451 3.84722 14.5263 3.82138 14.3942L2.85009 5.71426H13.118L12.141 14.3942ZM13.7656 4.57141H2.20257C2.04923 4.57141 1.90218 4.5112 1.79375 4.40404C1.68533 4.29688 1.62442 4.15153 1.62442 3.99998C1.62442 3.84843 1.68533 3.70309 1.79375 3.59592C1.90218 3.48876 2.04923 3.42856 2.20257 3.42856H13.7656C13.9189 3.42856 14.0659 3.48876 14.1744 3.59592C14.2828 3.70309 14.3437 3.84843 14.3437 3.99998C14.3437 4.15153 14.2828 4.29688 14.1744 4.40404C14.0659 4.5112 13.9189 4.57141 13.7656 4.57141Z" fill="#867C94" />
                                                                            <path d="M6.24958 13.1428C6.40291 13.1428 6.54996 13.0826 6.65839 12.9754C6.76681 12.8683 6.82772 12.7229 6.82772 12.5714V7.42854C6.82772 7.27699 6.76681 7.13164 6.65839 7.02448C6.54996 6.91731 6.40291 6.85711 6.24958 6.85711C6.09624 6.85711 5.94919 6.91731 5.84076 7.02448C5.73234 7.13164 5.67143 7.27699 5.67143 7.42854V12.5714C5.67143 12.7229 5.73234 12.8683 5.84076 12.9754C5.94919 13.0826 6.09624 13.1428 6.24958 13.1428Z" fill="#867C94" />
                                                                            <path d="M9.71847 13.1428C9.87181 13.1428 10.0189 13.0826 10.1273 12.9754C10.2357 12.8683 10.2966 12.7229 10.2966 12.5714V7.42854C10.2966 7.27699 10.2357 7.13164 10.1273 7.02448C10.0189 6.91731 9.87181 6.85711 9.71847 6.85711C9.56514 6.85711 9.41808 6.91731 9.30966 7.02448C9.20123 7.13164 9.14032 7.27699 9.14032 7.42854V12.5714C9.14032 12.7229 9.20123 12.8683 9.30966 12.9754C9.41808 13.0826 9.56514 13.1428 9.71847 13.1428Z" fill="#867C94" />
                                                                        </g>
                                                                        <defs>
                                                                            <clipPath id="clip0_8936_24044">
                                                                                <rect width="16" height="16" fill="white" />
                                                                            </clipPath>
                                                                        </defs>
                                                                    </svg>
                                                                </button>

                                                            </label>
                                                            <div className="tab-content">


                                                                <div className="row">
                                                                    <div className="input-coll">
                                                                        <label className={`labelStyle`}>First Name</label>
                                                                        <Field autoComplete="off" className="fields" type="text" name={`users.${index}.first_name`} placeholder="First Name" />
                                                                        <ErrorMessage name={`users.${index}.first_name`} component="div" className="error" />
                                                                    </div>

                                                                    <div className="input-coll">
                                                                        <label className={`labelStyle`}>Last Name</label>
                                                                        <Field autoComplete="off" className="fields" type="text" name={`users.${index}.last_name`} placeholder="Last Name" />
                                                                        <ErrorMessage name={`users.${index}.last_name`} component="div" className="error" />
                                                                    </div>

                                                                    <div className="input-coll">
                                                                        <label className={`labelStyle`}>Personal Email</label>
                                                                        <Field autoComplete="off" className="fields" type="email" name={`users.${index}.email`} placeholder="Email" />
                                                                        <ErrorMessage name={`users.${index}.email`} component="div" className="error" />
                                                                    </div>

                                                                    <div className="input-coll">
                                                                        <label className={`labelStyle`}>Phone Number</label>
                                                                        <Field
                                                                            autoComplete="off"
                                                                            className="fields"
                                                                            type="text"
                                                                            maxLength={14}
                                                                            name={`users.${index}.phone`}
                                                                            placeholder="Phone"
                                                                            value={formatPhoneNumber(values.users[index].phone)}
                                                                            onChange={(e) => {
                                                                                // Format phone number and update value

                                                                                setFieldValue(`users.${index}.phone`, formatPhoneNumber(e.target.value));
                                                                            }}
                                                                        />
                                                                        <ErrorMessage name={`users.${index}.phone`} component="div" className="error" />
                                                                    </div>

                                                                    <div className="input-coll">
                                                                        <label className={`labelStyle`}>Date Of Birth</label>
                                                                        <DatePicker
                                                                            autoComplete="off"
                                                                            name={`users.${index}.date_of_birth`}
                                                                            selected={values.users[index].date_of_birth ? moment(values.users[index].date_of_birth, 'YYYY-MM-DD').toDate() : null}
                                                                            onChange={(date) => setFieldValue(`users.${index}.date_of_birth`, date ? moment(date).format('YYYY-MM-DD') : "")}
                                                                            className="fields"
                                                                            dateFormat="yyyy-MM-dd"
                                                                            placeholderText="YYYY-MM-DD"
                                                                            maxDate={subYears(new Date(), 10)}

                                                                        />

                                                                        <ErrorMessage name={`users.${index}.date_of_birth`} component="div" className="error" />
                                                                    </div>
                                                                    <div className="input-coll">
                                                                        <Field type="text" name={`users.${index}.fax_one`} style={{ display: "none !important" }} hidden />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="input-coll w-100">
                                                                        <label className={`labelStyle`}>Personal Address</label>
                                                                        <PlacesAutocomplete
                                                                            autoComplete="off"
                                                                            value={values?.users[index].address}
                                                                            onChange={(text) => {
                                                                                setFieldValue(`users.${index}.address`, text);
                                                                            }}
                                                                            onSelect={(address) => handleSelect(address?.split(",")[0], setFieldValue, index)}
                                                                        >
                                                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                                                <div>
                                                                                    <input className="fields" {...getInputProps({ placeholder: 'Search owner address' })} name={`users.${index}.address`} />
                                                                                    <div className=' '>
                                                                                        {loading && <div>Loading...</div>}
                                                                                        {suggestions.map((suggestion) => {
                                                                                            const style = {
                                                                                                backgroundColor: suggestion.active ? '#41b6e6' : '#fff',
                                                                                                border: suggestion.active ? '1px solid #41b6e6' : '1px solid #ccc',
                                                                                                cursor: 'pointer',
                                                                                                width: "96%",
                                                                                                margin: "auto",
                                                                                                marginBottom: "1px"

                                                                                            };
                                                                                            return (
                                                                                                <div {...getSuggestionItemProps(suggestion, { style })} key={suggestion.placeId}>
                                                                                                    {suggestion.description}
                                                                                                </div>
                                                                                            );
                                                                                        })}
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </PlacesAutocomplete>
                                                                        <ErrorMessage name={`users.${index}.address`} component="div" className="error" />
                                                                    </div>

                                                                    <div className="input-coll">
                                                                        <label className={`labelStyle`}>Country</label>
                                                                        <Field autoComplete="off" className="fields" type="text" name={`users.${index}.country`} placeholder="Country" />
                                                                        <ErrorMessage name={`users.${index}.country`} component="div" className="error" />
                                                                    </div>

                                                                    <div className="input-coll">
                                                                        <label className={`labelStyle`}>State</label>
                                                                        <Field autoComplete="off" className="fields" type="text" name={`users.${index}.state`} placeholder="State" />
                                                                        <ErrorMessage name={`users.${index}.state`} component="div" className="error" />
                                                                    </div>

                                                                    <div className="input-coll">
                                                                        <label className={`labelStyle`}>City</label>
                                                                        <Field autoComplete="off" className="fields" type="text" name={`users.${index}.city`} placeholder="City" />
                                                                        <ErrorMessage name={`users.${index}.city`} component="div" className="error" />
                                                                    </div>

                                                                    <div className="input-coll">
                                                                        <label className={`labelStyle`}>Zip Code</label>
                                                                        <Field autoComplete="off" className="fields" type="text" name={`users.${index}.zip`} placeholder="Zip Code" />
                                                                        <ErrorMessage name={`users.${index}.zip`} component="div" className="error" />
                                                                    </div>


                                                                </div>

                                                                <div className="row">
                                                                    <div className="input-coll">
                                                                        <label className={`labelStyle`}>Position/Title</label>
                                                                        <Field autoComplete="off" as="select" className="fields" name={`users.${index}.b_title`}>

                                                                            <option value="" >Select title</option>
                                                                            <option value="CEO" >CEO</option>
                                                                            <option value="CFO">CFO </option>
                                                                            <option value="COO">COO</option>
                                                                            <option value="President">President</option>
                                                                            <option value="Secretary">Secretary </option>
                                                                            <option value="Treasurer">Treasurer</option>
                                                                            <option value="Vice President">Vice President</option>

                                                                        </Field>
                                                                        <ErrorMessage name={`users.${index}.b_title`} component="div" className="error" />
                                                                    </div>

                                                                    <div className="input-coll">
                                                                        <label className={`labelStyle`}>Social Security Number (SSN)</label>
                                                                        <Field autoComplete="off" maxLength={11} className="fields" type="text" name={`users.${index}.ssn`} placeholder="000-00-0000"

                                                                            value={formatSSN(values.users[index].ssn)}
                                                                            onChange={(e) => {
                                                                                // Format phone number and update value

                                                                                setFieldValue(`users.${index}.ssn`, formatSSN(e.target.value));
                                                                            }} />
                                                                        <ErrorMessage name={`users.${index}.ssn`} component="div" className="error" />
                                                                    </div>
                                                                    <div className="input-coll">
                                                                        <label className={`labelStyle`}>Drivers License Number</label>
                                                                        <Field autoComplete="off" className="fields" type="text" name={`users.${index}.license_number`} placeholder="Enter driver licence number" maxLength={15} />
                                                                        <ErrorMessage name={`users.${index}.license_number`} component="div" className="error" />
                                                                    </div>

                                                                    <div className="input-coll">
                                                                        <label className={`labelStyle`}>Ownership Percent</label>

                                                                        <NumericFormat
                                                                            className="fields"
                                                                            // decimalScale={2}
                                                                            // fixedDecimalScale={true}
                                                                            type="text"
                                                                            value={values.users[index].ownership_percent}
                                                                            placeholder="Ownership Percent"
                                                                            allowNegative={false}
                                                                            maxLength={6}
                                                                            isAllowed={(values) => {
                                                                                const { floatValue } = values;
                                                                                return floatValue === undefined || floatValue <= 100;
                                                                            }}
                                                                            onValueChange={(values) => {
                                                                                const { floatValue } = values;
                                                                                setFieldValue(`users.${index}.ownership_percent`, floatValue || "");
                                                                            }}
                                                                        />

                                                                        <ErrorMessage name={`users.${index}.ownership_percent`} component="div" className="error" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    )
                                                })}

                                            <div className="tab add-owner">
                                                <button
                                                    className="tab-label"
                                                    type="button"
                                                    disabled={values.users.length >= 4}
                                                    onClick={() => push({
                                                        first_name: '',
                                                        last_name: '',
                                                        email: '',
                                                        phone: formatPhoneNumber(`${Math.floor(Math.random() * 9999999999)}`),
                                                        date_of_birth: "1990-01-01",
                                                        address: '',
                                                        city: '',
                                                        state: '',
                                                        zip: '',
                                                        country: 'US',
                                                        b_title: '',
                                                        ssn: '',
                                                        ownership_percent: 100,
                                                        license_number: '',

                                                    })}
                                                >
                                                    + Add 1 more owner
                                                </button>
                                            </div>
                                            <ConfirmationModal
                                                isOpen={open}
                                                onClose={handleClose}
                                                onConfirm={() => confirmRemoveUser(remove)}
                                                name={userIndexToRemove?.name}
                                            />
                                        </div>
                                    )}
                                </FieldArray>
                                <div className='continue-sec'>
                                    <button className='continue-btn' type="submit">Continue</button>
                                </div>
                            </Form>
                        </>

                    )}
                </Formik>

            </div>
            {loading && (
                <div className="loader-container ">
                    <>
                        <div className="loader-background"></div> {/* Background blur */}
                        <div className="loader-content">
                            <Puff color="#ffffff" height={100} width={100} />
                        </div>
                    </>
                </div>
            )}
        </>

    );
};


// Modal component for confirmation
const ConfirmationModal = ({ isOpen, onClose, onConfirm, name }) => {
    if (!isOpen) return null;

    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            center
        >
            <div className="modal-overlay">
                <div className="modal-content">
                    <span><svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 20 20">
                        <path fill="currentColor" d="m9.129 0l1.974.005c.778.094 1.46.46 2.022 1.078c.459.504.7 1.09.714 1.728h5.475a.69.69 0 0 1 .686.693a.689.689 0 0 1-.686.692l-1.836-.001v11.627c0 2.543-.949 4.178-3.041 4.178H5.419c-2.092 0-3.026-1.626-3.026-4.178V4.195H.686A.689.689 0 0 1 0 3.505c0-.383.307-.692.686-.692h5.47c.014-.514.205-1.035.554-1.55C7.23.495 8.042.074 9.129 0Zm6.977 4.195H3.764v11.627c0 1.888.52 2.794 1.655 2.794h9.018c1.139 0 1.67-.914 1.67-2.794l-.001-11.627ZM6.716 6.34c.378 0 .685.31.685.692v8.05a.689.689 0 0 1-.686.692a.689.689 0 0 1-.685-.692v-8.05c0-.382.307-.692.685-.692Zm2.726 0c.38 0 .686.31.686.692v8.05a.689.689 0 0 1-.686.692a.689.689 0 0 1-.685-.692v-8.05c0-.382.307-.692.685-.692Zm2.728 0c.378 0 .685.31.685.692v8.05a.689.689 0 0 1-.685.692a.689.689 0 0 1-.686-.692v-8.05a.69.69 0 0 1 .686-.692ZM9.176 1.382c-.642.045-1.065.264-1.334.662c-.198.291-.297.543-.313.768l4.938-.001c-.014-.291-.129-.547-.352-.792c-.346-.38-.73-.586-1.093-.635l-1.846-.002Z" />
                    </svg></span>
                    <h3>Are you sure?</h3>
                    <p>Do you really want to remove {name}</p>
                    <button onClick={onConfirm} >
                        Sure
                    </button>
                    <button onClick={onClose}  >
                        Cancel
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default StepOneForm;
