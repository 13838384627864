import React, { useState } from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Puff } from 'react-loader-spinner';
import AvgNumberformt from './utils/AvgNumberformt';
import PercentNumberFormat from './utils/PercentNumberFormat';
const StepThree = ({ setStep, step, clientDetails, ownerInfo, setFormData }) => {

  const apiUrl = process.env.REACT_APP_API_URL;
  const { clientkey, merchant_id } = useParams();
  const [loading, setLoading] = useState(false);

  const initialValues = {
    avgTicket: ownerInfo?.avgTicket ? ownerInfo?.avgTicket : '',
    annualCCSales: ownerInfo?.annualCCSales ? ownerInfo?.annualCCSales : '',
    highest_ticket: ownerInfo?.highest_ticket ? ownerInfo?.highest_ticket : '',
    legal_b2c: ownerInfo?.legal_b2c ? ownerInfo?.legal_b2c : '',
    legal_b2b: ownerInfo?.legal_b2b ? ownerInfo?.legal_b2b : '',
    legal_cnpinternet: ownerInfo?.legal_cnpinternet ? ownerInfo?.legal_cnpinternet : '',
    legal_cnpphoneemail: ownerInfo?.legal_cnpphoneemail ? ownerInfo?.legal_cnpphoneemail : '',
    legal_percentdeliveredover30days: ownerInfo?.legal_percentdeliveredover30days ? ownerInfo?.legal_percentdeliveredover30days : '',
    legal_percentdeliveredsameday: ownerInfo?.legal_percentdeliveredsameday ? ownerInfo?.legal_percentdeliveredsameday : '',
    percentdelievered7days: ownerInfo?.percentdelievered7days ? ownerInfo?.percentdelievered7days : '',
    percentdelivered14days: ownerInfo?.percentdelivered14days ? ownerInfo?.percentdelivered14days : '',
    legal_cp: ownerInfo?.legal_cp ? ownerInfo?.legal_cp : '',
    legal_keyed: ownerInfo?.legal_keyed ? ownerInfo?.legal_keyed : ''
  };

  const validationSchema = Yup.object({
    avgTicket: Yup.number().typeError('Average ticket must be a number').required('Average ticket is required'),
    annualCCSales: Yup.number().typeError('Annual Credit Card Sales must be a number').required('Annual Credit Card Sales is required'),
    highest_ticket : Yup.number().typeError('Highest Ticket must be a number').optional('Highest Ticket is required'),
    legal_b2c: Yup.number().min(0, "Should be minimum 0").max(100, "Cannot exceed 100").typeError('B2C Transactions must be a number').required('B2C Transactions are required'),
    legal_b2b: Yup.number().min(0, "Should be minimum 0").max(100, "Cannot exceed 100").typeError('B2B Transactions must be a number').required('B2B Transactions are required'),
    legal_cnpinternet: Yup.number().min(0, "Should be minimum 0").max(100, "Cannot exceed 100").typeError('Manual Entry via Internet must be a number').required('Manual Entry via Internet is required'),
    legal_cnpphoneemail: Yup.number().min(0, "Should be minimum 0").max(100, "Cannot exceed 100").typeError('Manual Entry via Phone/Email must be a number').required('Manual Entry via Phone/Email is required'),
    legal_percentdeliveredover30days: Yup.number().min(0, "Should be minimum 0").max(100, "Cannot exceed 100").typeError('Deferred Delivery > 30 Days must be a number').required('Deferred Delivery > 30 Days is required'),
    legal_percentdeliveredsameday: Yup.number().min(0, "Should be minimum 0").max(100, "Cannot exceed 100").typeError('Same-Day Delivery must be a number').required('Same-Day Delivery is required'),
    percentdelievered7days: Yup.number().min(0, "Should be minimum 0").max(100, "Cannot exceed 100").typeError('Deferred Delivery > 7 Days must be a number').required('Deferred Delivery > 7 Days is required'),
    percentdelivered14days: Yup.number().min(0, "Should be minimum 0").max(100, "Cannot exceed 100").typeError('Deferred Delivery > 14 Days must be a number').required('Deferred Delivery > 14 Days is required'),
    legal_cp: Yup.number().min(0, "Should be minimum 0").max(100, "Cannot exceed 100").typeError('Card Present must be a number').required('Card Present is required'),
    legal_keyed: Yup.number().min(0, "Should be minimum 0").max(100, "Cannot exceed 100").typeError('Card Not Present must be a number').required('Card Not Present is required'),
    // fax_three: Yup.string()
  });

  // handle form submit form

  const stepThirdHandler = async (values) => {
    try {
      values = {
        ...values,
        avgTicket: Number(values?.avgTicket),
        annualCCSales: Number(values?.annualCCSales),
        highest_ticket: Number(values?.highest_ticket),
      }
      let data = {
        ...values,
        merchant_id,
        step: 3,
      }
      let saveStepTwoToLocalStorage = JSON.stringify({ ...ownerInfo, ...values })
      localStorage.setItem('client-details', saveStepTwoToLocalStorage);
      //  
      setLoading(true);
      if (merchant_id) {
        const response = await axios.post(
          `${apiUrl}/merchant/onboarding/${clientkey}`, data);
        if (response.data.result) {
          toast.success(response?.data?.message);
          setStep(step + 1);
        }
        else {
          toast.error(response?.data?.message);
        }
      }
      else {
        setFormData((pre) => ({ ...pre, ...values }))
        setStep(step + 1);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (typeof (error?.response?.data?.message) === 'object') {
        error?.response?.data?.message?.map((item) => {
          return toast.error(item)
        })
      } else {
        toast.error(error?.response?.data?.message || "Something went wrong");
      }
      console.log(error);
    }
  }

  // validate fields
  const ValidateFields = (values) => {
    const errors = {};
    if (Number(values.legal_b2c) + Number(values.legal_b2b) !== 100) {
      errors.legal_b2c = 'B2B and B2C should total 100%';
      errors.legal_b2b = 'B2B and B2C should total 100%';
    }
    if (Number(values.legal_cp) + Number(values.legal_keyed) !== 100) {
      errors.legal_cp = 'Card Present and Card Not Present should total 100%';
      errors.legal_keyed = 'Card Present and Card Not Present should total 100%';
    }
    if (Number(values.legal_cnpinternet) + Number(values.legal_cnpphoneemail) !== 100) {
      errors.legal_cnpinternet = 'Manual Entry via Phone/Email, internet should total 100%';
      errors.legal_cnpphoneemail = 'Manual Entry via Phone/Email, internet should total 100%';
    }
    if (Number(values.legal_percentdeliveredover30days) + Number(values.legal_percentdeliveredsameday) + Number(values.percentdelivered14days) + Number(values.percentdelievered7days) !== 100) {
      errors.legal_percentdeliveredover30days = 'Deferred Delivery greater then 7, 14, 30  Days should total 100%, ';
      errors.legal_percentdeliveredsameday = 'Deferred Delivery greater then 7, 14, 30  Days should total 100%, ';
      errors.percentdelivered14days = 'Deferred Delivery greater then 7, 14, 30  Days should total 100%, ';
      errors.percentdelievered7days = 'Deferred Delivery greater then 7, 14, 30  Days should total 100%, ';
    }
    return errors;
  }

  return (
    <div className="">
      <div className='client-logo'>
        <img src={clientDetails?.logo} alt='logo' />
      </div>
      <div className="container all-steps">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          validate={(values) => {
            let errors = ValidateFields(values);
            return errors;
          }}
          onSubmit={(values) => {
            // if (values?.fax_three !== "") { return false; }
            stepThirdHandler(values);
          }}
        >
          {({ values, setFieldValue, field }) => (
            <Form>
              <div className="heading-container">

                <div className="heading-text">
                  <h2>Processing Information</h2>
                </div>
                <div className="heading-ico">
                  <img src="/owner-info.png" alt="Owner Info" />
                </div>
              </div>
              <div className="">
              <div className="row">
                <AvgNumberformt field={field} setFieldValue={setFieldValue} values={values} name={'avgTicket'} placeholder={'$'} labelName={'Average Transaction Amount'} />
                <AvgNumberformt field={field} setFieldValue={setFieldValue} values={values} name={'annualCCSales'} placeholder={'$'} labelName={'Annual Processing Volume '} />
                <AvgNumberformt field={field} setFieldValue={setFieldValue} values={values} name={'highest_ticket'} placeholder={'$'} labelName={'Highest Transaction Amount'} />

                {/* New Row */}
                <div style={{ width: "100%" }}></div>

                <PercentNumberFormat field={field} setFieldValue={setFieldValue} values={values} name={'legal_b2c'} placeholder={'B2C Transactions %'} labelName={'Business to Consumer Transactions'} 
                onChange={(value) => {
                  const b2c = parseFloat(value) || 0;
                  if (b2c <= 100) {
                    setFieldValue('legal_b2b', (100 - b2c))
                  }
                }}/>
                <PercentNumberFormat field={field} setFieldValue={setFieldValue} values={values} name={'legal_b2b'} placeholder={'B2B Transactions %'} labelName={'Business to Business Transactions'}
                onChange={(value) => {
                  const b2b = parseFloat(value) || 0;
                  if (b2b <= 100) {
                    setFieldValue('legal_b2c', (100 - b2b))
                  }
                }} />

                {/* New Row */}
                <div style={{ width: "100%" }}></div>

                <PercentNumberFormat field={field} setFieldValue={setFieldValue} values={values} name={'legal_cp'} placeholder={'Swiped Payments %'} labelName={'Card Present'}
                onChange={(value) => {
                  const cp = parseFloat(value) || 0;
                  if (cp <= 100) {
                    setFieldValue('legal_keyed', (100 - cp))
                  }
                }} />
                <PercentNumberFormat field={field} setFieldValue={setFieldValue} values={values} name={'legal_keyed'} placeholder={'Keyed Payments %'} labelName={'Card Not Present'}
                onChange={(value) => {
                  const keyed = parseFloat(value) || 0;
                  if (keyed <= 100) {
                    setFieldValue('legal_cp', (100 - keyed))
                  }
                }} />

                {/* New Row */}
                <div style={{ width: "100%" }}></div>

                <PercentNumberFormat field={field} setFieldValue={setFieldValue} values={values} name={'legal_cnpphoneemail'} placeholder={'Payments taken via Phone/Email %'} labelName={'Keyed Payments via Phone/Email'}
                onChange={(value) => {
                  const cnpPhone = parseFloat(value) || 0;
                  if (cnpPhone <= 100) {
                    setFieldValue('legal_cnpinternet', (100 - cnpPhone))
                  }
                }} />
                <PercentNumberFormat field={field} setFieldValue={setFieldValue} values={values} name={'legal_cnpinternet'} placeholder={'Payments taken via Internet %'} labelName={'Keyed Payments via Internet'}
                onChange={(value) => {
                  const cnpInternet = parseFloat(value) || 0;
                  if (cnpInternet <= 100) {
                    setFieldValue('legal_cnpphoneemail', (100 - cnpInternet))
                  }
                }} />
              </div>
              <div className='heading-text mt-all'>
                  <h2>Post-Transaction Info</h2>
                  <br />
                  <h5>Please specify when the ownership of sold goods is transferred to the customer. Ensure that all fields total 100%.</h5>
                </div>
                <br />
                <div className='row'>
                  <PercentNumberFormat field={field} setFieldValue={setFieldValue} values={values} name={'legal_percentdeliveredsameday'} placeholder={'%'} labelName={'Same-Day Delivery'} />
                  <PercentNumberFormat field={field} setFieldValue={setFieldValue} values={values} name={'percentdelievered7days'} placeholder={'%'} labelName={`Deferred Delivery > 7 Days`} />
                  <PercentNumberFormat field={field} setFieldValue={setFieldValue} values={values} name={'percentdelivered14days'} placeholder={'%'} labelName={`Deferred Delivery > 14 Days`} />
                  <PercentNumberFormat field={field} setFieldValue={setFieldValue} values={values} name={'legal_percentdeliveredover30days'} placeholder={'%'} labelName={'Deferred Delivery > 30 Days'} />
                </div>
                {/* <div className="input-coll">
                  <Field type="text" name={`fax_three`} style={{ display: "none !important" }} hidden />
                </div> */}
                <div className='Btn1div'>
                  <button type='button' onClick={() => setStep(step - 1)} className='PrevBtn'>Previous</button>
                  <button type="submit" className='FirstPgBtn '>Continue</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {loading && (
        <div className="loader-container ">
          <>
            <div className="loader-background"></div> {/* Background blur */}
            <div className="loader-content">
              <Puff color="#ffffff" height={100} width={100} />
            </div>
          </>
        </div>
      )}
    </div>
  );
};

export default StepThree;

