import { ErrorMessage } from 'formik';
import React from 'react'
import { NumericFormat } from 'react-number-format';

const PercentNumberFormat = ({ field, setFieldValue, values, name, placeholder, labelName, onChange }) => {


    return (
         <div className='input-coll'>
            <label className='labelStyle' >{labelName}</label>
            <NumericFormat
                className='fields'
                maxLength={4}
                suffix={'%'}
                {...field}
                value={values[name] !== undefined ? `${values[name]}` : ''}
                placeholder={`${placeholder}`}
                onChange={(e) => {
                    let numericValue = parseInt(e.target.value.replace('%', ''));
                    setFieldValue(name, numericValue);

                    if (onChange) {
                        onChange(numericValue);
                    }
                }}
            />
            <ErrorMessage name={name} component='div' className='error' />
        </div>
    )

}

export default PercentNumberFormat